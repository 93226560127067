import React from "react";
import SlickSlider from "../Components/SlickSlider";

function Legal() {
  return (
    <>
      <section class="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2>Legal</h2>
            </div>
          </div>
        </div>
      </section>

      <SlickSlider />
    </>
  );
}

export default Legal;
