import React from "react";

function DevelopmentPipeline() {
  return (
    <section
      id="pipeline"
      className="ds color background_cover page_features section_padding_top_150 section_padding_bottom_150"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-md-offset-4 col-lg-offset-6">
            <h2 className="section_header">Development pipeline</h2>
            <p className="highlight bottommargin_20">
              <strong>
                Balanced mix of new substance developments and life cycle
                management opportunities.
              </strong>
            </p>
            <p>
              We focus on core competencies and its many years of experience. We
              thus hold a leading position in many therapeutic fields.
            </p>
            <div className="row topmargin_40 columns_margin_bottom_20">
              <div className="col-sm-4 col-xs-6 col-xxs-12">
                <div className="vertical-item teaser clear-media hover_bg_teaser ls with_padding rounded with_shadow min_height_165">
                  <p className="big highlight2 bottommargin_20 topmargin_5">
                    01
                  </p>
                  <h5 className="margin_0">Research &amp; Preclinical</h5>
                  <div className="media-links clear-media">
                    {" "}
                    <a
                      href="pipeline-single.html"
                      className="abs-link"
                    ></a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-6 col-xxs-12">
                <div className="vertical-item teaser clear-media hover_bg_teaser ls with_padding rounded with_shadow min_height_165">
                  <p className="big highlight2 bottommargin_20 topmargin_5">
                    02
                  </p>
                  <h5 className="margin_0">Starting Phase</h5>
                  <div className="media-links clear-media">
                    {" "}
                    <a
                      href="pipeline-single.html"
                      className="abs-link"
                    ></a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-6 col-xxs-12">
                <div className="vertical-item teaser clear-media hover_bg_teaser ls with_padding rounded with_shadow min_height_165">
                  <p className="big highlight2 bottommargin_20 topmargin_5">
                    03
                  </p>
                  <h5 className="margin_0">Medium Phase</h5>
                  <div className="media-links clear-media">
                    {" "}
                    <a
                      href="pipeline-single.html"
                      className="abs-link"
                    ></a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-6 col-xxs-12">
                <div className="vertical-item teaser clear-media hover_bg_teaser ls with_padding rounded with_shadow min_height_165">
                  <p className="big highlight2 bottommargin_20 topmargin_5">
                    04
                  </p>
                  <h5 className="margin_0">Finishing Phase</h5>
                  <div className="media-links clear-media">
                    {" "}
                    <a
                      href="pipeline-single.html"
                      className="abs-link"
                    ></a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-6 col-xxs-12">
                <div className="vertical-item teaser clear-media hover_bg_teaser ls with_padding rounded with_shadow min_height_165">
                  <p className="big highlight2 bottommargin_20 topmargin_5">
                    05
                  </p>
                  <h5 className="margin_0">After Drug Approval</h5>
                  <div className="media-links clear-media">
                    {" "}
                    <a
                      href="pipeline-single.html"
                      className="abs-link"
                    ></a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-6 col-xxs-12">
                <div className="vertical-item teaser clear-media hover_bg_teaser ls with_padding rounded with_shadow min_height_165">
                  <p className="big highlight2 bottommargin_20 topmargin_5">
                    06
                  </p>
                  <h5 className="margin_0">Drugs Production</h5>
                  <div className="media-links clear-media">
                    {" "}
                    <a
                      href="pipeline-single.html"
                      className="abs-link"
                    ></a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DevelopmentPipeline;
