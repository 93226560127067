import React from "react";

function Footer() {
  return (
    <>
      <footer className="page_footer ds color section_padding_top_100 section_padding_bottom_65 table_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="widget widget_text">
                {" "}
                <a
                  href="index.html"
                  className="logo vertical_logo bottommargin_20"
                >
                  <img src="images/logo.jpg" alt=""></img>
                </a>
                <p>
                  We are a pharmaceutical company dedicated to the discovery,
                  development, and commercialization of innovative therapies
                  aimed at improving outcomes for patients suffering from
                  various conditions.
                </p>
                {/* <p className="darklinks">
                  {" "}
                  <a
                    className="social-icon soc-facebook"
                    href="#"
                    title="Facebook"
                  ></a>
                  <a
                    className="social-icon soc-twitter"
                    href="#"
                    title="Twitter"
                  ></a>{" "}
                  <a
                    className="social-icon soc-google"
                    href="#"
                    title="Twitter"
                  ></a>{" "}
                  <a
                    className="social-icon soc-linkedin"
                    href="#"
                    title="Twitter"
                  ></a>{" "}
                  <a
                    className="social-icon soc-youtube"
                    href="#"
                    title="Youtube"
                  ></a>{" "}
                </p> */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6"></div>
            <div className="col-md-3 col-sm-6">
              <div className="widget widget_text topmargin_25">
                <h4 className="widget-title">Contact Us</h4>
                <div className="media">
                  <div className="media-left rightpadding_10">
                    {" "}
                    <i
                      className="fa fa-map-marker highlight"
                      aria-hidden="true"
                    ></i>{" "}
                  </div>
                  <div className="media-body">
                    Plot No. 68, Khasra No. 1680 Hanumant Enclave, Salempur,
                    Bahadrabad, Haridwar 249402
                  </div>
                </div>
                <div className="media">
                  <div className="media-left rightpadding_10">
                    {" "}
                    <i
                      className="fa fa-phone highlight"
                      aria-hidden="true"
                    ></i>{" "}
                  </div>
                  <div className="media-body"> 0 (800) 123 4567 </div>
                </div>
                <div className="media">
                  <div className="media-left rightpadding_10">
                    {" "}
                    <i
                      className="fa fa-pencil highlight"
                      aria-hidden="true"
                    ></i>{" "}
                  </div>
                  <div className="media-body highlight2links">
                    info@novavision.in
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="ds color darker page_copyright section_padding_15">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <p className="fontsize_12">
                &copy; Copyright 2024. All Rights Reserved.{" "}
                <a href="https://datamanindia.com" target="_blank">
                  Dataman
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
