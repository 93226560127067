import React, { useState, useEffect, useRef } from "react";
import Isotope from "isotope-layout";
import "magnific-popup/dist/magnific-popup.css";
import $ from "jquery";
import "magnific-popup";

const Products = () => {
  const [filterKey, setFilterKey] = useState("*");
  const isotopeInstance = useRef(null);
  const magnificPopupInitialized = useRef(false);

  useEffect(() => {
    isotopeInstance.current = new Isotope(".isotope-container", {
      itemSelector: ".isotope-item",
      layoutMode: "masonry",
    });

    return () => {
      isotopeInstance.current.destroy();
      $.magnificPopup.close();
    };
  }, []);

  useEffect(() => {
    if (isotopeInstance.current) {
      isotopeInstance.current.arrange({
        filter: filterKey === "*" ? "*" : `.${filterKey}`,
      });
    }
  }, [filterKey]);

  useEffect(() => {
    if (!magnificPopupInitialized.current) {
      $(".popup-links").magnificPopup({
        type: "image",
        gallery: {
          enabled: true,
        },
        removalDelay: 300, // Duration of the fade-out effect in milliseconds
        mainClass: "mfp-fade", // Class for the fade effect
      });
      magnificPopupInitialized.current = true;
    }
  }, []);

  const handleFilterChange = (key) => {
    setFilterKey(key);
  };

  const portfolioItems = [
    {
      category: "alo",
      imgSrc: "images/Products/Pridesic-Sp-Thumb.jpg",
      title: "Pridesic-SP",
      link: "images/Products/Pridesic-Sp.jpg",
      tags: ["Medical"],
    },
    {
      category: "alo",
      imgSrc: "images/Products/Priderub-DSR-Thumb.jpg",
      title: "Priderub-DSR",
      link: "images/Products/Priderub-DSR.jpg",
      tags: ["Medical"],
    },
    {
      category: "alo",
      imgSrc: "images/Products/Pridemont-M-Thumb.jpg",
      title: "Pridemont-M",
      link: "images/Products/Pridemont-M.jpg",
      tags: ["Medical"],
    },
    {
      category: "alo",
      imgSrc: "images/Products/Pridelactin-Thumb.jpg",
      title: "Pridelactin",
      link: "images/Products/Pridelactin.jpg",
      tags: ["Medical"],
    },
    {
      category: "alo",
      imgSrc: "images/Products/NVK-Thumb.jpg",
      title: "NV K26",
      link: "images/Products/NVK.jpg",
      tags: ["Medical"],
    },
    {
      category: "ayu",
      imgSrc: "images/Products/A-Orava-Thumb.jpg",
      title: "Orava M",
      link: "images/Products/A-Orava.jpg",
      tags: ["Cosmetic"],
    },
    {
      category: "ayu",
      imgSrc: "images/Products/A-OnionHairOil-Thumb.jpg",
      title: "Onion Hair Oil",
      link: "images/Products/A-OnionHairOil.jpg",
      tags: ["Cosmetic"],
    },
    {
      category: "ayu",
      imgSrc: "images/Products/A-N-HepaNova-Thumb.jpg",
      title: "N-Hepa Nova",
      link: "images/Products/A-N-HepaNova.jpg",
      tags: ["Supplement"],
    },
    {
      category: "ayu",
      imgSrc: "images/Products/A-DIABISAFE-Thumb.jpg",
      title: "DIABISAFE SYRUP",
      link: "images/Products/A-DIABISAFE.jpg",
      tags: ["Syrup"],
    },
    {
      category: "ayu",
      imgSrc: "images/Products/A-Anshderma-Thumb.jpg",
      title: "Anshderma-14",
      link: "images/Products/A-Anshderma.jpg",
      tags: ["Cosmetic"],
    },
    {
      category: "ayu",
      imgSrc: "images/Products/Zympride-Thumb.jpg",
      title: "Zymepride",
      link: "images/Products/Zympride.jpg",
      tags: ["Syrup"],
    },
  ];

  return (
    <section className="ls page_portfolio section_padding_top_150 section_padding_bottom_150">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="filters isotope_filters greylinks">
              <a
                href="#"
                className={filterKey === "*" ? "selected" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  handleFilterChange("*");
                }}
              >
                All
              </a>
              <a
                href="#"
                className={filterKey === "alo" ? "selected" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  handleFilterChange("alo");
                }}
              >
                Allopathic
              </a>
              <a
                href="#"
                className={filterKey === "ayu" ? "selected" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  handleFilterChange("ayu");
                }}
              >
                Ayurvedic
              </a>
            </div>
            <div className="isotope-container isotope row masonry-layout columns_margin_bottom_20">
              {portfolioItems.map((item, index) => (
                <div
                  key={index}
                  className={`isotope-item col-lg-4 col-md-6 col-sm-12 ${item.category}`}
                >
                  <article className="vertical-item content-padding big-padding post format-standard with_border rounded text-center">
                    <div className="item-media">
                      <a href={item.link} className="popup-links">
                        <img src={item.imgSrc} alt={item.title} />
                      </a>
                    </div>
                    <div className="item-content">
                      <header className="entry-header">
                        <div className="categories-links highlight2links">
                          {item.tags.map((tag, idx) => (
                            <a key={idx} href="#">
                              {tag}
                            </a>
                          ))}
                        </div>
                        <h4 className="entry-title topmargin_0">
                          {item.title}
                        </h4>
                      </header>
                      <p>{item.description}</p>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
