import React from "react";
import { useNavigate } from "react-router-dom";

function WhyChooseUs() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/about");
  };

  return (
    <section
      id="features"
      class="ls section_padding_top_150 section_padding_bottom_130 columns_margin_bottom_30"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <h2 class="section_header">Why Choose Us?</h2>{" "}
            <button
              className="theme_button color2 inverse min_width_button"
              onClick={handleClick}
            >
              About Us
            </button>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="teaser media hover_icon">
              <div class="media-left">
                <div class="teaser_icon rounded main_bg_color size_small">
                  {" "}
                  <i class="rt-icon2-diamond2"></i>{" "}
                </div>
              </div>
              <div class="media-body toppadding_10">
                <h5 class="hover-color2">
                  <a href="#">Newest Technologies</a>
                </h5>
              </div>
            </div>
            <div class="teaser media hover_icon">
              <div class="media-left">
                <div class="teaser_icon rounded main_bg_color size_small">
                  {" "}
                  <i class="rt-icon2-cloud"></i>{" "}
                </div>
              </div>
              <div class="media-body toppadding_10">
                <h5 class="hover-color2">
                  <a href="#">Taking Care of Nature</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="teaser media hover_icon">
              <div class="media-left">
                <div class="teaser_icon rounded main_bg_color size_small">
                  {" "}
                  <i class="rt-icon2-tag2"></i>{" "}
                </div>
              </div>
              <div class="media-body toppadding_10">
                <h5 class="hover-color2">
                  <a href="#">Fair Prices</a>
                </h5>
              </div>
            </div>
            <div class="teaser media hover_icon">
              <div class="media-left">
                <div class="teaser_icon rounded main_bg_color size_small">
                  {" "}
                  <i class="rt-icon2-cloud"></i>{" "}
                </div>
              </div>
              <div class="media-body toppadding_10">
                <h5 class="hover-color2">
                  <a href="#">High Customer Satisfaction</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
