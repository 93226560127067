import React from "react";

function ContactUs(props) {
  return (
    <section
      id="contact"
      className="ls section_padding_top_150 section_padding_bottom_150"
    >
      <div className="container">
        <div className="row">
          <div
            className={`col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 ${props.class}`}
          >
            <div className="background_cover contact-form-wrapper">
              <h2 className="section_header">Contact Form</h2>
              <form
                className="contact-form row"
                method="post"
                action="https://html.modernwebtemplates.com/pharma/"
              >
                <div className="col-sm-6">
                  <div className="contact-form-name">
                    {" "}
                    <label for="name">
                      Full Name <span className="required">*</span>
                    </label>{" "}
                    <input
                      type="text"
                      aria-required="true"
                      size="30"
                      value=""
                      name="name"
                      id="name"
                      className="form-control text-center"
                      placeholder="Name"
                    ></input>{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="contact-form-email">
                    {" "}
                    <label for="email">
                      Email address<span className="required">*</span>
                    </label>{" "}
                    <input
                      type="email"
                      aria-required="true"
                      size="30"
                      value=""
                      name="email"
                      id="email"
                      className="form-control text-center"
                      placeholder="Email Address"
                    ></input>{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="contact-form-phone">
                    {" "}
                    <label for="phone">
                      Phone<span className="required">*</span>
                    </label>{" "}
                    <input
                      type="text"
                      aria-required="true"
                      size="30"
                      value=""
                      name="phone"
                      id="phone"
                      className="form-control text-center"
                      placeholder="Phone Number"
                    ></input>{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="contact-form-subject">
                    {" "}
                    <label for="subject">
                      Subject<span className="required">*</span>
                    </label>{" "}
                    <input
                      type="text"
                      aria-required="true"
                      size="30"
                      value=""
                      name="subject"
                      id="subject"
                      className="form-control text-center"
                      placeholder="Your Subject"
                    ></input>{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="contact-form-message">
                    {" "}
                    <label for="message">Message</label>
                    <textarea
                      aria-required="true"
                      rows="4"
                      cols="45"
                      name="message"
                      id="message"
                      className="form-control text-center"
                      placeholder="Message..."
                    ></textarea>{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="contact-form-submit topmargin_10">
                    {" "}
                    <button
                      type="submit"
                      id="contact_form_submit"
                      name="contact_submit"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Send Message
                    </button>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
