// Loader.js
import React from "react";
import "../Loader.css"; // Make sure to create corresponding CSS for the loader

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
