import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

function Numbering() {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });

  return (
    <section className="cs gradient section_padding_top_65 section_padding_bottom_50 columns_margin_bottom_20">
      <div className="container">
        <div className="row">
          <div className="col-md-20 col-sm-4 col-xs-6 col-xxs-12">
            <div className="teaser text-center" ref={ref1}>
              {inView1 && (
                <h3 className="counter-wrap">
                  <CountUp start={0} end={5} duration={2.1} />
                  <span className="counter-add"></span>
                </h3>
              )}
              <p>Years Experience</p>
            </div>
          </div>

          <div className="col-md-20 col-sm-4 col-xs-6 col-xxs-12">
            <div className="teaser text-center" ref={ref2}>
              {inView2 && (
                <h3 className="counter-wrap">
                  <CountUp start={0} end={10} duration={2.1} />
                  <span className="counter-add"></span>
                </h3>
              )}
              <p>Products</p>
            </div>
          </div>

          <div className="col-md-20 col-sm-4 col-xs-6 col-xxs-12">
            <div className="teaser text-center" ref={ref4}>
              {inView4 && (
                <h3 className="counter-wrap">
                  <CountUp start={0} end={173} duration={2.1} />
                  <span className="counter-add">k</span>
                </h3>
              )}
              <p>Happy Clients</p>
            </div>
          </div>

          <div className="col-md-20 col-sm-4 col-xs-6 col-xxs-12">
            <div className="teaser text-center" ref={ref5}>
              {inView5 && (
                <h3 className="counter-wrap">
                  <CountUp start={0} end={75} duration={2.1} />
                  <span className="counter-add"></span>
                </h3>
              )}
              <p>Partners</p>
            </div>
          </div>

          <div className="col-md-20 col-sm-4 col-xs-6 col-xxs-12">
            <div className="teaser text-center" ref={ref1}>
              {inView1 && (
                <h3 className="counter-wrap">
                  <CountUp start={0} end={2} duration={2.1} />
                  <span className="counter-add"></span>
                </h3>
              )}
              <p>Vertical</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Numbering;
