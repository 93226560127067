import React, { useRef, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";

const OwlCarsol = () => {
  const carouselRef = useRef(null);

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    onTranslate: () => handleHideContent(),
    onTranslated: () => handleShowAnimation(),
  };

  const items = [
    {
      id: 1,
      background: "url(images/slide01.jpg)",
      title: "Welcome to NOVAVISION MEDICARE",
      text: "The heart of your healthcare",
      button: "Learn More",
    },
    {
      id: 2,
      background: "url(images/slide02.jpg)",
      title: "Welcome to NOVAVISION MEDICARE",
      text: "The heart of your healthcare",
      button: "Learn More",
    },
    {
      id: 3,
      background: "url(images/slide03.jpg)",
      title: "Welcome to NOVAVISION MEDICARE",
      text: "The heart of your healthcare",
      button: "Learn More",
    },
    // Add more items as needed
  ];

  const handleHideContent = () => {
    const activeItems = document.querySelectorAll(".owl-item .content");
    activeItems.forEach((item) => {
      item.classList.add("hidden");
      item.classList.remove("animated-zoomIn");
    });
  };

  const handleShowAnimation = () => {
    const activeItems = document.querySelectorAll(".owl-item.active .content");
    activeItems.forEach((item) => {
      setTimeout(() => {
        item.classList.remove("hidden");
        item.classList.add("animated-zoomIn");
      }, 100); // Adjust the delay as needed
    });
  };

  const navgaiteaboutus = () => {
    window.location = "/about";
  };

  useEffect(() => {
    handleShowAnimation(); // Initial load
  }, []);

  return (
    <div className="owl-carousel-container">
      <OwlCarousel className="owl-theme" {...options} ref={carouselRef}>
        {items.map((item) => (
          <div
            key={item.id}
            className="item"
            style={{ backgroundImage: item.background }}
          >
            <div className="content hidden">
              <h4>{item.title}</h4>
              <p>{item.text}</p>
              <button onClick={navgaiteaboutus}>{item.button}</button>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default OwlCarsol;
