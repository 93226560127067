import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../ProductSlider.css";

const ProductSlider = () => {
  const mainSlider = useRef(null);
  const navSlider = useRef(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  useEffect(() => {
    setNav1(mainSlider.current);
    setNav2(navSlider.current);
  }, []);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    asNavFor: nav2,
    infinite: true,
    speed: 500,
    cssEase: "linear",
  };

  const settingsNav = {
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    asNavFor: nav1,
    infinite: true,
    speed: 500,
    cssEase: "linear",
  };

  return (
    <section
      id="products"
      className="ds color parallax page_gallery section_padding_top_100 section_padding_bottom_150 columns_padding_25"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="section_header">Our Products</h2>
            <p className="highlight bottommargin_20">
              <strong>
                We have several allopathic and Ayurvedic products for pain
                relief, diabetes, liver care, derma, hair care, etc.
              </strong>
            </p>
            <p className="topmargin_30"></p>

            <div className="nav-slider-container">
              <Slider {...settingsNav} ref={navSlider}>
                <div>
                  <img
                    src="images/Products/Pridesic-Sp-Thumb.jpg"
                    alt="Thumbnail 1"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/Priderub-DSR-Thumb.jpg"
                    alt="Thumbnail 2"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/Pridemont-M-Thumb.jpg"
                    alt="Thumbnail 3"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/Pridelactin-Thumb.jpg"
                    alt="Thumbnail 4"
                  />
                </div>
                <div>
                  <img src="images/Products/NVK-Thumb.jpg" alt="Thumbnail 5" />
                </div>
                <div>
                  <img
                    src="images/Products/A-Orava-Thumb.jpg"
                    alt="Thumbnail 6"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-OnionHairOil-Thumb.jpg"
                    alt="Thumbnail 7"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-N-HepaNova-Thumb.jpg"
                    alt="Thumbnail 8"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-DIABISAFE-Thumb.jpg"
                    alt="Thumbnail 9"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-Anshderma-Thumb.jpg"
                    alt="Thumbnail 10"
                  />
                </div>
              </Slider>
            </div>

            <p className="topmargin_30">
              <a
                href="gallery-regular-3-cols.html"
                className="theme_button color2 inverse"
              >
                See All Products
              </a>
            </p>
          </div>
          <div className="col-md-6">
            <div className="main-slider-container">
              <Slider {...settingsMain} ref={mainSlider}>
                <div>
                  <img src="images/Products/Pridesic-Sp.jpg" alt="Product 1" />
                </div>
                <div>
                  <img src="images/Products/Priderub-DSR.jpg" alt="Product 2" />
                </div>
                <div>
                  <img src="images/Products/Pridemont-M.jpg" alt="Product 3" />
                </div>
                <div>
                  <img src="images/Products/Pridelactin.jpg" alt="Product 4" />
                </div>
                <div>
                  <img src="images/Products/NVK.jpg" alt="Product 5" />
                </div>
                <div>
                  <img src="images/Products/A-Orava.jpg" alt="Product 6" />
                </div>
                <div>
                  <img
                    src="images/Products/A-OnionHairOil.jpg"
                    alt="Product 7"
                  />
                </div>
                <div>
                  <img src="images/Products/A-N-HepaNova.jpg" alt="Product 8" />
                </div>
                <div>
                  <img src="images/Products/A-DIABISAFE.jpg" alt="Product 9" />
                </div>
                <div>
                  <img src="images/Products/A-Anshderma.jpg" alt="Product 10" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSlider;
