import React from "react";
import Numbering from "../Components/Numbering";
import WhyChooseUs from "../Components/WhyChooseUs";

function About() {
  return (
    <>
      <section class="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2>About Us</h2>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="ls section_padding_top_150 section_padding_bottom_150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-left">
              <h2
                className="section_header"
                style={{ textAlign: "left !important" }}
              >
                About Us{" "}
              </h2>
              <hr></hr>
              <p className="text-justify">
                NovaVision Medicare, founded in 2020 by Shri Brijendra Nishad,
                has swiftly emerged as a dynamic force in the pharmaceutical
                industry. Specializing in both allopathic and Ayurvedic
                medicines, NovaVision Medicare has established a robust presence
                in various therapeutic segments, including diabetes, liver care,
                antibiotics, hair care, and dermatology.
              </p>
              <p className="text-justify">
                Under the visionary leadership of Shri Brijendra Nishad,
                NovaVision Medicare is committed to providing high-quality,
                effective, and affordable healthcare solutions. The company's
                allopathic range addresses critical healthcare needs with
                precision and scientific rigor. Their diabetes care line, for
                example, features advanced formulations that help manage blood
                sugar levels effectively, improving the quality of life for
                patients. In liver care, NovaVision Medicare offers innovative
                solutions that support liver health and function, addressing
                conditions such as hepatitis and cirrhosis with proven efficacy.
              </p>
              <p className="text-justify">
                In addition to allopathic medicines, NovaVision Medicare places
                a strong emphasis on Ayurvedic formulations, drawing on the rich
                tradition of natural and holistic healing. The Ayurvedic range
                includes products for hair care and skin health, harnessing the
                power of natural ingredients to promote wellness and vitality.
                Their hair care products are designed to combat hair loss,
                dandruff, and other common issues, while the dermatology range
                offers solutions for various skin conditions, ensuring healthy
                and radiant skin.
              </p>
              <p className="text-justify">
                NovaVision Medicare's commitment to quality is reflected in
                their state-of-the-art manufacturing facilities, which adhere to
                stringent quality control standards. Each product undergoes
                rigorous testing to ensure safety, efficacy, and consistency.
                This dedication to excellence has earned the company the trust
                of healthcare professionals and patients alike.
              </p>
              <p className="text-justify">
                Innovation is at the heart of NovaVision Medicare's approach.
                The company's research and development team continuously
                explores new avenues to enhance their product offerings and
                address unmet medical needs. By combining traditional wisdom
                with modern science, NovaVision Medicare is poised to make
                significant contributions to the healthcare industry.
              </p>
              <p className="text-justify">
                As NovaVision Medicare continues to grow and evolve, their
                mission remains clear: to improve health outcomes and enhance
                the well-being of individuals through superior pharmaceutical
                products. With a focus on innovation, quality, and
                patient-centric care, NovaVision Medicare is set to achieve new
                milestones in the years to come.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Numbering />
      <WhyChooseUs />
    </>
  );
}

export default About;
