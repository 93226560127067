import React from "react";
import WelcomeOurSite from "../Components/WelcomeOurSite";
import OurProducts from "../Components/OurProducts";
import WhyChooseUs from "../Components/WhyChooseUs";
import Numbering from "../Components/Numbering";
import DevelopmentPipeline from "../Components/DevelopmentPipeline";
import Blog from "../Components/Blog";
import Testinomials from "../Components/Testinomials";
import ContactUs from "../Components/ContactUs";
import SliderBottom from "../Components/SliderBottom";
import Slider from "../Components/Slider";
import OwlCarsol from "../Components/OwlCarsol";
import ProductSlider from "../Components/ProductSlider";

function Home() {
  return (
    <>
      <OwlCarsol />
      <WelcomeOurSite />
      <ProductSlider />
      <WhyChooseUs />
      <Numbering />
      <DevelopmentPipeline />
      <ContactUs class="text-center" />
    </>
  );
}

export default Home;
