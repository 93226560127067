import React from "react";

function WelcomeOurSite() {
  return (
    <section
      id="about"
      className="ls section_padding_top_150 section_padding_bottom_150"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="section_header">Welcome to NOVAVISION MEDICARE </h2>
            <h4>The heart of your healthcare</h4>
            <p>
              We are a pharmaceutical company dedicated to the discovery,
              development, and commercialization of innovative therapies aimed
              at improving outcomes for patients suffering from various
              conditions. Our mission is to advance healthcare by providing
              cutting-edge solutions that address unmet medical needs, enhancing
              the quality of life for individuals worldwide. By combining
              rigorous scientific research with a patient-centric approach, we
              strive to deliver effective and safe treatments that make a
              meaningful difference in patients' lives.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WelcomeOurSite;
