import React from "react";
import Numbering from "../Components/Numbering";
import WhyChooseUs from "../Components/WhyChooseUs";

function PridesicSP() {
  return (
    <>
      <section class="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2>PridesicSP</h2>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="ls section_padding_top_150 section_padding_bottom_150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-left">
              <h2
                className="section_header"
                style={{ textAlign: "left !important" }}
              >
                PridesicSP{" "}
              </h2>
              <hr></hr>
              <p className="text-justify">PridesicSP</p>
            </div>
          </div>
        </div>
      </section>

      <Numbering />
      <WhyChooseUs />
    </>
  );
}

export default PridesicSP;
