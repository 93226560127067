import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";

function Header() {
  return (
    <>
      <section className="page_topline cs main_color2 table_section table_section_sm section_padding_top_5 section_padding_bottom_5">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 text-center text-sm-left">
              <div>
                {" "}
                <i
                  className="fa fa-clock-o rightpadding_5"
                  aria-hidden="true"
                ></i>{" "}
                Opening Hours: Mon - Sat 8.00 - 18.00{" "}
              </div>
            </div>
            <div className="col-sm-4 text-center text-sm-right greylinks">
              {" "}
              <span className="rightpadding_10">Follow Us:</span>{" "}
              <a
                className="social-icon color-icon soc-facebook"
                href="#"
                title="Facebook"
              ></a>
              <a
                className="social-icon color-icon soc-twitter"
                href="#"
                title="Twitter"
              ></a>{" "}
              <a
                className="social-icon color-icon soc-google"
                href="#"
                title="Twitter"
              ></a>{" "}
              <a
                className="social-icon color-icon soc-linkedin"
                href="#"
                title="Twitter"
              ></a>{" "}
              <a
                className="social-icon color-icon soc-youtube"
                href="#"
                title="Youtube"
              ></a>{" "}
            </div>
          </div>
        </div>
      </section>
      <section className="page_toplogo table_section table_section_md ls section_padding_top_25 section_padding_bottom_25">
        <div className="container">
          <div className="row">
            <div className="col-md-3 text-center text-md-left">
              {" "}
              <a href="index.html" className="logo top_logo">
                <img src="images/logo.jpg" alt=""></img>
              </a>{" "}
            </div>
            <div className="col-md-9 text-center text-md-right">
              <div className="inline-teasers-wrap">
                <div className="teaser small-teaser media">
                  <div className="media-left media-middle">
                    <div className="teaser_icon size_small border_icon highlight2 rounded">
                      {" "}
                      <i className="rt-icon2-pen"></i>{" "}
                    </div>
                  </div>
                  <div className="media-body media-middle">
                    <h4>0 (800) 123 4567</h4>
                    <p className="greylinks fontsize_16 highlight2 ">
                      {" "}
                      info@novavision.in
                    </p>
                  </div>
                </div>
                <div className="teaser small-teaser media">
                  <div className="media-left media-middle">
                    <div className="teaser_icon size_small border_icon highlight2 rounded">
                      {" "}
                      <i className="rt-icon2-map-pin"></i>{" "}
                    </div>
                  </div>
                  <div className="media-body media-middle">
                    <h4> Plot No. 68, Khasra No. 1680</h4>
                    <p className="greylinks fontsize_12">
                      Hanumant Enclave, Salempur, Bahadrabad, Haridwar 249402
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <header className="page_header header_color toggler_left">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 display_table">
              <div className="header_mainmenu display_table_cell">
                <nav className="mainmenu_wrapper">
                  <ul className="mainmenu nav sf-menu">
                    <li>
                      {""}
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/products">
                        Products
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link" to="/contact">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </nav>
                <span className="toggle_menu">
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
